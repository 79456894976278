@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .herobutton {
    @apply border border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all duration-300 px-4 py-1 sm:px-6 sm:py-2 hover:border-[#F7AB0A]/40 hover:text-[#F7AB0A]/40
  }

  .contactInput{
    @apply outline-none bg-slate-400/10 rounded-sm border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#F7AB0A]/40 focus:text-[#F7AB0A]/40 hover:border-[#F7AB0A]/40
  }
}